import { Button } from "@components/Button"
import Instagram from "@svg/social/instagram.svg"
import LinkedIn from "@svg/social/linkedin.svg"
import React from "react"
import { TextLoop } from "react-text-loop-next"

export const Home = () => {
  return (
    <>
      <div style={{ height: "94px" }}></div>
      <div className="h-hero sm:min-h-780px lg:min-h-850px">
        <div className="container flex flex-wrap items-start pt-clamp-16-26">
          <div className="w-full lg:w-10/12 lg:mx-auto">
            <h1 data-aos="home-stagger" className="text-white text-home-headline">
              <span style={{ display: "block" }}>What’s</span> <span>your</span>{" "}
              <span className="text-gray-darkest">
                <TextLoop springConfig={{ stiffness: 475, damping: 25 }}>
                  <span>brand</span>
                  <span>talent</span>
                  <span>digital</span>
                  <span>sustainability</span>
                </TextLoop>
              </span>
              <span style={{ display: "block" }}>
                sto<span className="tracking-wider">ry</span>?
              </span>
            </h1>
          </div>
          <div className="grid w-full grid-cols-6 md:grid-cols-12 mt-15 md:mt-4">
            <div className="col-span-1">
              <div className="flex-col hidden mt-auto md:flex gap-y-5" style={{ width: "1.25rem" }}>
                <a
                  href="https://www.linkedin.com/company/jk-design/mycompany/"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="LinkedIn"
                  className="flex justify-center text-white transition-all duration-300 fill-current sm:hover:text-gray-darkest"
                >
                  <LinkedIn />
                </a>
                <a
                  href="https://www.instagram.com/jk.design/"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Instagram"
                  className="flex justify-center text-white transition-all duration-300 fill-current sm:hover:text-gray-darkest"
                >
                  <Instagram />
                </a>
              </div>
            </div>
            <div className="col-span-5 md:col-span-10" id="home-hero-sub-headline">
              <div className="pb-24 ml-auto text-white break-words pl-7 md:pl-0" style={{ maxWidth: "470px" }}>
                <h2 className="text-26" data-aos="fade-right" data-aos-anchor="#home-hero-sub-headline">
                  We’re expert storytellers.
                </h2>
                <div
                  className="mt-6 md:mt-11"
                  data-aos="fade-right"
                  data-aos-delay="100"
                  data-aos-anchor="#home-hero-sub-headline"
                >
                  <p className="text-18">
                    We are a <span className="font-bold">women-owned brand and communications agency</span> with 39
                    years of experience in branding, marketing, engagement, and social good communications.
                  </p>
                  <p className="text-18">
                    But what makes all of these truly powerful is a compelling story—a reason for people to take notice,
                    to engage, to believe. And that’s our specialty.
                  </p>
                </div>

                <div
                  className="mt-11"
                  data-aos="fade-right"
                  data-aos-delay="200"
                  data-aos-anchor="#home-hero-sub-headline"
                >
                  <Button link="/contact" className="text-white sm:hover:text-gray-darkest">
                    Let’s chat
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
