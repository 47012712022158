import React from "react"
import PropTypes from "prop-types"

export const Hero = ({
  children,
  className = "h-hero min-h-736px pb-clamp-33-76 pt-clamp-16-44",
  containerClass = "container flex flex-wrap justify-around items-start",
}) => {
  return (
    <>
      <div style={{ height: "94px" }}></div>
      <div className={className}>
        <div className={containerClass}>{children}</div>
      </div>
    </>
  )
}

const Title = ({ children }) => {
  return <div className="mx-auto lg:w-10/12 text-headline">{children}</div>
}

const Message = ({ children, override = false }) => {
  if (override) {
    return <>{children}</>
  }
  if (!override) {
    return (
      <div className="grid w-full grid-cols-4 sm:grid-cols-6 lg:grid-cols-10 mt-11">
        <div className="col-span-3 col-start-2 sm:col-start-4 lg:col-start-4 lg:col-span-7">
          <div data-aos="fade-up">{children}</div>
        </div>
      </div>
    )
  }
}

Hero.Title = Title
Hero.Message = Message

Hero.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

Title.propTypes = {
  children: PropTypes.node,
}

Message.propTypes = {
  children: PropTypes.node,
  override: PropTypes.bool,
}
